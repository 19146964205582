import React, { Component } from 'react'
import Layout from '../components/Layout'
import Lightbox from 'lightbox-react'
import le101 from '../../static/img/portfolio/101A_woodward_2022.jpg'
import le102 from '../../static/img/portfolio/102_sallie_mae_2022.jpg'
import le103 from '../../static/img/portfolio/103A_abbott_northwestern_2022.jpg'
import le104 from '../../static/img/portfolio/104A_gerding_theater_2022.jpg'
import le105 from '../../static/img/portfolio/105A_akshar_developers_2022.jpg'
import le106 from '../../static/img/portfolio/106_louisiana_2022.jpg'
import le107 from '../../static/img/portfolio/107A_corporate_building_2022.jpg'
import le108 from '../../static/img/portfolio/108_corporate_building_2022.jpg'
import le1000a from '../../static/img/portfolio/1000_abbott_northwestern_2022.jpg'
import le2000n from '../../static/img/portfolio/2000N_northwestern_2_2022.jpg'

const images = [
  {
    img: le101,
    caption: '<h2>LEVELe-101A</h2>',
    link: 'https://www.forms-surfaces.com/levele-101-elevator-interiors'
  },
  {
    img: le102,
    caption: '<h2>LEVELe-102</h2>',
    link: 'https://www.forms-surfaces.com/levele-102-elevator-interiors'
  },
  {
    img: le103,
    caption: '<h2>LEVELe-103A</h2>',
    link: 'https://www.forms-surfaces.com/levele-103-elevator-interiors'
  },
  {
    img: le104,
    caption: '<h2>LEVELe-104A</h2>',
    link: 'https://www.forms-surfaces.com/levele-104-elevator-interiors'
  },
  {
    img: le105,
    caption: '<h2>LEVELe-105A</h2>',
    link: 'https://www.forms-surfaces.com/levele-105-elevator-interiors'
  },
  {
    img: le106,
    caption: '<h2>LEVELe-106</h2>',
    link: 'https://www.forms-surfaces.com/levele-106-elevator-interiors'
  },
  {
    img: le107,
    caption: '<h2>LEVELe-107A</h2>',
    link: 'https://www.forms-surfaces.com/levele-107-elevator-interiors'
  },
  {
    img: le108,
    caption: '<h2>LEVELe-108</h2>',
    link: 'http://www.forms-surfaces.com/levele-108-elevator-interiors'
  },
  {
    img: le1000a,
    caption: '<h2>LEVELc-1000</h2>',
    link: 'https://www.forms-surfaces.com/levelc-1000-elevator-interiors'
  },
  {
    img: le2000n,
    caption: '<h2>LEVELc-2000</h2>',
    link: 'https://www.forms-surfaces.com/levelc-2000-elevator-interiors'
  }
]
export default class App extends Component {
  constructor(props) {
    super(props)

    this.state = {
      photoIndex: 0,
      isOpen: false,
    }
  }
  render(){
    const { photoIndex, isOpen } = this.state
    return(
      <Layout wrapperClass='portfolio-page'>
        <div className='row'>
          <div className='col-lg-12 hdiw-intro'>
            <p>TOUR OUR PORTFOLIO! See how you can use EDS renderings to assist
            with your elevator interior designs.
            </p>
          </div>
        </div>
        <div className='row portfolio-grid'>
          <div className='col-lg-3'>
            <button  onClick={() => this.setState({ isOpen: true, photoIndex: 0 })}>
            <img src={le101} alt="LEVELe-101" />
            </button>
          </div>
          <div className='col-lg-3'>
            <button  onClick={() => this.setState({ isOpen: true, photoIndex: 1 })}>
            <img src={le102} alt="LEVELe-102" />
            </button>
          </div>
          <div className='col-lg-3'>
            <button  onClick={() => this.setState({ isOpen: true, photoIndex: 2 })}>
            <img src={le103} alt="LEVELe-103" />
            </button>
          </div>
          <div className='col-lg-3'>
            <button onClick={() => this.setState({ isOpen: true, photoIndex: 3 })}>
              <img src={le104} alt="LEVELe-104" />
            </button>
          </div>
          <div className='col-lg-3'>
            <button  onClick={() => this.setState({ isOpen: true, photoIndex: 4 })}>
              <img src={le105} alt="LEVELe-104" />
            </button>
          </div>
          <div className='col-lg-3'>
            <button onClick={() => this.setState({ isOpen: true, photoIndex: 5 })}>
              <img src={le106} alt="LEVELe-106"  />
            </button>
          </div>
          <div className='col-lg-3'>
            <button onClick={() => this.setState({ isOpen: true, photoIndex: 6 })}>
              <img src={le107} alt="LEVELe-107" />
            </button>
          </div>
          <div className='col-lg-3'>
            <button onClick={() => this.setState({ isOpen: true, photoIndex: 7 })}>
              <img src={le108} alt="LEVELe-108" />
            </button>
          </div>
          <div className='col-lg-3'>
            <button onClick={() => this.setState({ isOpen: true, photoIndex: 8 })}>
              <img src={le1000a} alt="LEVELc-1000A"  />
            </button>
          </div>
          <div className='col-lg-3'>
            <button onClick={() => this.setState({ isOpen: true, photoIndex: 9 })}>
              <img src={le2000n} alt="LEVELc-2000N"  />
            </button>
          </div>
        </div>
        {isOpen && (
          <Lightbox
            enableZoom={false}
            mainSrc={images[photoIndex].img}
            imageCaption=<div className='portfolio-caption'>
              <div className='row'>
                <div className='col-lg-10'>
                  <div dangerouslySetInnerHTML={{__html: images[photoIndex].caption}} />
                </div>
                {images[photoIndex].link &&
                  <div className='col-lg-2'>
                    <a href={images[photoIndex].link} target='_blank' rel='noreferrer'>LEARN MORE</a>
                  </div>
                }
              </div>
            </div>
            imagePadding={10}
            nextSrc={images[(photoIndex + 1) % images.length].img}
            prevSrc={images[(photoIndex + images.length - 1) % images.length].img}
            onCloseRequest={() => this.setState({ isOpen: false })}
            onMovePrevRequest={() =>
              this.setState({
                photoIndex: (photoIndex + images.length - 1) % images.length,
              })
            }
            onMoveNextRequest={() =>
              this.setState({
                photoIndex: (photoIndex + 1) % images.length,
              })
            }
          />
        )}
      </Layout>
    )
  }
}
